import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  CircularProgress,
  Tooltip,
  Button,
} from '@material-ui/core';
import {
  Save as SaveIcon,
  HighlightOff as HighlightOffIcon,
} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';

import { SectionWrapper, ToggleTickSwitch } from 'components';
import { USER_SERVICES, PMS_SERVICES } from 'Services';
import { Context } from 'context';
import { ADD_NOTIFICATION } from 'context/actions';
import GroupUtils from 'Shared/GroupUtils';
import PmsDropdown from 'Views/Common/PmsDropdown';
import PatientConsent from 'Views/Common/PatientConsent';
import { FIRM_NAME_MAPPING } from 'Views/Common/enum';
import styles from './Capabilities.module.scss';

const Capabilities = (props) => {
  const {
    name = '',
    levelData = {},
    autolinking = false,
    patientSearch = false,
    showEmployeeSupport = false,
    showPmsIntegration = false,
    showDuplicateAppts = false,
    showAutoAssignPlan = false,
    showAllowGetAddons = false,
    showAllowFacility = false,
    showEnableInsurancPlan = false,
    showSeparateLocation = false,
    showPatientConsent = false,
    settings: {
      capture_patient_id = false,
      allow_manual_appt = false,
      pms_auto_link = false,
      duplicate_pms_appt = false,
      allow_facility = false,
      pms_discount_mode = 'discount',
      location_separate = false,
      menu_config: { pms_patient_details = false, employee_list = false } = {},
      setting_level,
      pms_vendor = [],
      pms_info = {},
      auto_location_plan = true,
      allow_get_addons = true,
      patient_form_info: { vendor = '', config_name = '', form_url = '' } = {},
    } = {},
    onUpdate,
  } = props;

  const { partner_id, group_id, region_id, location_id } = levelData;
  const [vendor_name] = pms_vendor;
  const vendor_firmname = pms_info[FIRM_NAME_MAPPING[vendor_name]];
  const pms_location_id =
    pms_info?.pms_location_id || pms_info?.nexhealth_location_id;

  const { dispatch: globalDispatch } = useContext(Context);

  const [isLoading, setIsLoading] = useState(false);
  const [capturePatientId, setCapturePatientId] = useState(capture_patient_id);
  const [duplicatePmsAppt, setDuplicatePmsAppt] = useState(duplicate_pms_appt);
  const [separateLocation, setSeparateLocation] = useState(location_separate);
  const [pmsAutolink, setPmsAutolink] = useState(pms_auto_link);
  const [allowFacility, setAllowFacility] = useState(allow_facility);
  const [pmsPatientSearch, setPmsPatientSearch] = useState(pms_patient_details);
  const [employeeSupport, setEmployeeSupport] = useState(employee_list);
  const [autoAssignPlan, setAutoAssignPlan] = useState(auto_location_plan);
  const [allowGetAddons, setAllowGetAddons] = useState(allow_get_addons);
  const [pmsDiscountCode, setPmsDiscountCode] = useState(
    pms_discount_mode !== 'discount',
  );

  const [vendorsList, setVendorsList] = useState([]);
  const [vendorsLoading, setVendorsLoading] = useState(false);
  const [vendorName, setVendorName] = useState(vendor_name);
  const [vendorFirmname, setVendorFirmname] = useState(vendor_firmname);
  const [validateLoading, setValidateLoading] = useState(false);
  const [pmsLocationId, setPmsLocationId] = useState(pms_location_id);
  const [validateLocationLoading, setValidateLocationLoading] = useState(false);
  const [reqFields, setReqFields] = useState({});

  const [consentVendorsLoading, setConsentVendorsLoading] = useState(false);
  const [consentVendorsList, setConsentVendorsList] = useState([]);
  const [enablePatientConsent, setEnablePatientConsent] = useState(Boolean(vendor));
  const [enableManualAppointment, setEnableManualAppointment] = useState(allow_manual_appt);
  const [consentVendorName, setConsentVendorName] = useState(vendor);
  const [consentConfigName, setConsentConfigName] = useState(config_name);
  const [consentFormUrl, setConsentFormUrl] = useState(form_url);

  const handleSwitchChange = async (event) => {
    const { name: eName, checked } = event.target;

    if (eName === 'capturePatientId') {
      setCapturePatientId(checked);

      if (checked) {
        setReqFields({
          ...reqFields,
          vendor_name: {
            value: '',
            error: false,
            isValid: false,
            text: '',
          },
        });
      }
      setVendorName('');
      setVendorFirmname('');
    } else if (eName === 'enablePatientConsent') {
      setEnablePatientConsent(checked);
    } else if (eName === 'duplicatePmsAppt') {
      setDuplicatePmsAppt(checked);

      saveSettingsData('appointment', checked);
    } else if (eName === 'pmsAutolink') {
      setPmsAutolink(checked);

      saveSettingsData('link', checked);
    } else if (eName === 'allowFacility') {
      setAllowFacility(checked);

      saveSettingsData('allowFacility', checked);
    } else if (eName === 'separateLocation') {
      setSeparateLocation(checked);

      saveSettingsData('separateLocation', checked);
    } else if (eName === 'pmsPatientSearch') {
      setPmsPatientSearch(checked);

      saveSettingsData('search', checked);
    } else if (eName === 'employeeSupport') {
      setEmployeeSupport(checked);

      saveSettingsData('employee', checked);
    } else if (eName === 'autoAssignPlan') {
      setAutoAssignPlan(checked);

      saveSettingsData('autoAssignPlan', checked);
    } else if (eName === 'allowGetAddons') {
      setAllowGetAddons(checked);

      saveSettingsData('allowGetAddons', checked);
    } else if (eName === 'pmsDiscountCode') {
      setPmsDiscountCode(checked);

      saveSettingsData('pmsDiscountCode', GroupUtils.getDiscountType(checked));
    } else if (eName === 'enableManualAppointment') {
      setEnableManualAppointment(checked)
      saveSettingsData('enableManualAppointment', checked);
    }
  };

  const levelPayload = {
    [setting_level]: {
      location_id,
      region_id,
      group_id,
      partner_id,
    },
  };

  const saveSettingsData = async (type = 'capture', value = '') => {
    setIsLoading(type);

    try {
      let settingsData = {};

      if (type === 'capture') {
        if (!capture_patient_id && capture_patient_id !== capturePatientId) {
          settingsData = {
            capture_patient_id: true,
            pms_vendor: [vendorName],
            pms_info: { [FIRM_NAME_MAPPING[vendorName]]: vendorFirmname },
          };

          if (pmsLocationId) {
            settingsData.pms_info.pms_location_id = pmsLocationId;
          }
        }
      } else if (type === 'consent') {
        if (enablePatientConsent) {
          settingsData = {
            patient_form_info: {
              vendor: consentVendorName,
              config_name: consentConfigName,
              form_url: consentFormUrl
            }
          };
        }
        else {
          settingsData.patient_form_info = null;
        }
      } else if (type === 'appointment') {
        settingsData = {
          duplicate_pms_appt: value,
        };
      } else if (type === 'link') {
        settingsData = {
          pms_auto_link: value,
        };
      } else if (type === 'allowFacility') {
        settingsData = {
          allow_facility: value,
        };
      } else if (type === 'separateLocation') {
        settingsData = {
          location_separate: value,
        };
      } else if (type === 'search') {
        settingsData = {
          menu_config: {
            pms_patient_details: value,
          },
        };
      } else if (type === 'employee') {
        settingsData = {
          menu_config: {
            employee_list: value,
          },
        };
      } else if (type === 'autoAssignPlan') {
        settingsData = {
          auto_location_plan: value,
        };
      } else if (type === 'allowGetAddons') {
        settingsData = {
          allow_get_addons: value,
        };
      } else if (type === 'pmsDiscountCode') {
        settingsData = {
          pms_discount_mode: value,
        };
      } else if (type === 'enableManualAppointment') {
        settingsData = {
          allow_manual_appt: value,
        };
      }

      const payload = { ...levelPayload, setting: settingsData };

      const response = await USER_SERVICES.updateLevels(payload, setting_level);

      if (response?.type === 'success') {
        dispatchGlobalNotification(
          'success',
          'Capability updated successfully',
        );
        onUpdate && onUpdate();
      } else {
        throw response?.message || response?.error;
      }
    } catch (error) {
      dispatchGlobalNotification(
        'error',
        error || 'Error updating the capability details',
      );
    }

    setIsLoading(false);
  };

  const onVendorSelect = (evt) => {
    const vendorVal = evt?.target?.value || '';

    let locReqFields = {};
    if (
      ['nexhealth', 'opendental', 'athena', 'denticon', 'carestack'].includes(
        vendorVal,
      )
    ) {
      locReqFields = {
        pms_location_id: {
          value: '',
          error: false,
          isValid: false,
          text: '',
        },
      };
    }

    setVendorName(vendorVal);
    setReqFields({
      ...reqFields,
      vendor_name: {
        ...reqFields.vendor_name,
        value: vendorVal,
        error: !vendorVal,
        isValid: Boolean(vendorVal),
        text: !vendorVal ? 'Please choose a vendor' : '',
      },
      vendor_firmname: {
        value: '',
        error: false,
        isValid: false,
        text: '',
      },
      ...locReqFields,
    });
  };

  const onConsentVendorSelect = (evt) => {
    const vendorVal = evt?.target?.value || '';

    setConsentVendorName(vendorVal);
    setReqFields({
      ...reqFields,
      vendor: {
        ...reqFields.vendor,
        value: vendorVal,
        error: !vendorVal,
        isValid: Boolean(vendorVal),
        text: !vendorVal ? 'Please choose a vendor' : '',
      },
    });
  };

  const onConsentConfignameChange = (evt) => {
    const configValue = evt?.target?.value;

    setConsentConfigName(configValue);
    setReqFields({
      ...reqFields,
      config_name: {
        ...reqFields.config_name,
        value: configValue,
        error: !configValue,
        isValid: !!configValue,
        text: 'Config name is required',
      },
    });
  };

  const onConsentFormUrlChange = (evt) => {
    const formValue = evt?.target?.value;

    setConsentFormUrl(formValue);
    setReqFields({
      ...reqFields,
      form_url: {
        ...reqFields.form_url,
        value: formValue,
        error: !formValue,
        isValid: !!formValue,
        text: 'Form Url is required',
      },
    });
  };

  const onFirmnameChange = (evt) => {
    const firmValue = evt?.target?.value;

    setVendorFirmname(firmValue);
    setReqFields({
      ...reqFields,
      vendor_firmname: {
        ...reqFields.vendor_firmname,
        value: firmValue,
        error: false,
        isValid: false,
        text: '',
      },
      pms_location_id: {
        ...reqFields.pms_location_id,
        error: false,
        isValid: false,
        text: '',
      },
    });
  };

  const onLocIdChange = (evt) => {
    const locationIdValue = evt?.target?.value;

    setPmsLocationId(locationIdValue);
    setReqFields({
      ...reqFields,
      pms_location_id: {
        ...reqFields.pms_location_id,
        value: locationIdValue,
        error: false,
        isValid: false,
        text: '',
      },
    });
  };

  const handleValidateFirmname = async () => {
    setValidateLoading(true);
    try {
      if (vendorFirmname && vendorFirmname.length > 0) {
        const payload = {
          vendor: vendorName,
          [FIRM_NAME_MAPPING[vendorName]]: vendorFirmname,
        };

        const vResponse = await PMS_SERVICES.validatePms(payload);

        if (vResponse?.type === 'success') {
          const respData = vResponse?.data;

          if (
            respData?.location_required !== undefined &&
            !respData?.location_required
          ) {
            const defaultPMSLocationId = '0';
            setPmsLocationId(defaultPMSLocationId);
            setReqFields({
              ...reqFields,
              vendor_firmname: {
                ...reqFields.vendor_firmname,
                error: false,
                isValid: true,
              },
              pms_location_id: {
                ...reqFields?.pms_location_id,
                value: defaultPMSLocationId,
                error: false,
                isValid: true,
                text: '',
              },
            });
          } else {
            setReqFields({
              ...reqFields,
              vendor_firmname: {
                ...reqFields.vendor_firmname,
                error: false,
                isValid: true,
              },
            });
          }
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log(e);

      setReqFields({
        ...reqFields,
        vendor_firmname: {
          ...reqFields.vendor_firmname,
          error: true,
          isValid: false,
          text: 'Enter a valid firm name',
        },
      });
    }
    setValidateLoading(false);
  };

  const handleValidateLocationId = async () => {
    if (!group_id || !partner_id) return;

    setValidateLocationLoading(true);
    try {
      if (pmsLocationId) {
        const payload = {
          vendor: vendorName,
          pms_firm_name: vendorFirmname,
          pms_location_id: pmsLocationId,
        };
        const vResponse = await PMS_SERVICES.validatePms(payload);

        if (vResponse?.type === 'success') {
          setReqFields({
            ...reqFields,
            pms_location_id: {
              ...reqFields.pms_location_id,
              error: false,
              isValid: true,
            },
          });
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log(e);

      setReqFields({
        ...reqFields,
        pms_location_id: {
          ...reqFields.pms_location_id,
          error: true,
          isValid: false,
          text: 'Enter valid location id',
        },
      });
    }
    setValidateLocationLoading(false);
  };

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } },
    });

  const fetchVendorsList = async () => {
    setVendorsLoading(true);
    try {
      const vResponse = await PMS_SERVICES.getVendorsList();

      if (vResponse?.type === 'success') {
        setVendorsList(vResponse?.data?.vendors);
      } else {
        console.log('Error fetching vendors details');
      }
    } catch (e) {
      console.log(e);
    }
    setVendorsLoading(false);
  };

  const fetchConsentVendorsList = async () => {
    setConsentVendorsLoading(true);

    try {
      const vResponse = await PMS_SERVICES.getConsentVendorsList();

      if (vResponse?.type === 'success' && vResponse?.data) {
        setConsentVendorsList(vResponse?.data?.vendors);
      } else {
        throw vResponse?.message || vResponse?.Error;
      }
    } catch (e) {
      console.log(e || 'Error fetching vendors details');

      setConsentVendorsList([]);
    } finally {
      setConsentVendorsLoading(false);
    }
  };

  useEffect(() => {
    fetchVendorsList();
    fetchConsentVendorsList();
  }, []);

  useEffect(() => {
    setPmsAutolink(pms_auto_link);
  }, [pms_auto_link]);

  useEffect(() => {
    setAllowFacility(allow_facility);
  }, [allow_facility]);

  useEffect(() => {
    setSeparateLocation(location_separate);
  }, [location_separate]);

  useEffect(() => {
    setDuplicatePmsAppt(duplicate_pms_appt);
  }, [duplicate_pms_appt]);

  useEffect(() => {
    setPmsPatientSearch(pms_patient_details);
  }, [pms_patient_details]);

  useEffect(() => {
    capture_patient_id && setCapturePatientId(capture_patient_id);
    vendor_name && setVendorName(vendor_name);
    vendor_firmname && setVendorFirmname(vendor_firmname);
    pms_location_id && setPmsLocationId(pms_location_id);
  }, [capture_patient_id]);

  useEffect(() => {
    vendor && setEnablePatientConsent(Boolean(vendor));
    vendor && setConsentVendorName(vendor);
    config_name && setConsentConfigName(config_name);
    form_url && setConsentFormUrl(form_url);
  }, [vendor]);

  useEffect(() => {
    setAllowGetAddons(allow_get_addons);
  }, [allow_get_addons]);

  const renderTooltip = (message) => (
    <Tooltip title={message}>
      <InfoIcon
        color="primary"
        data-testid="heading-info-button"
        fontSize="small"
        className="ml-5 cursor-pointer"
      />
    </Tooltip>
  );

  const checkIfPmsTouched = () => {
    let formTouched = false;
    let formValid = true;

    if (
      capture_patient_id !== capturePatientId &&
      vendorName &&
      vendorFirmname
    ) {
      formTouched = true;
    }

    if (capture_patient_id !== capturePatientId) {
      if (!reqFields['vendor_firmname']?.isValid) {
        formValid = false;
      }

      if (
        (vendorName === 'nexhealth' ||
          vendorName === 'opendental' ||
          vendorName === 'athena') &&
        Boolean(setting_level === 'location') &&
        !reqFields['pms_location_id']?.isValid
      ) {
        formValid = false;
      }
    }

    return formTouched && formValid;
  };

  const checkConsentFormTouched = () => {
    let formTouched = false;

    if (!enablePatientConsent) {
      formTouched = Boolean(vendor) !== enablePatientConsent;
    } else {
      formTouched =
        consentVendorName &&
        consentConfigName &&
        consentFormUrl &&
        (consentVendorName !== vendor ||
          consentConfigName !== config_name ||
          consentFormUrl !== form_url);
    }

    return formTouched;
  };

  const pmsUpdated = checkIfPmsTouched();
  const consentFormUpdated = checkConsentFormTouched();

  const resetSettingsData = () => {
    setCapturePatientId(capture_patient_id);
    vendor_name && setVendorName(vendor_name);
    vendor_firmname && setVendorFirmname(vendor_firmname);
    pms_location_id && setPmsLocationId(pms_location_id);

    setEnablePatientConsent(Boolean(vendor));
    setConsentVendorName(vendor);
    setConsentConfigName(config_name);
    setConsentFormUrl(form_url);
  };

  return (
    <SectionWrapper
      title={`${name} Capabilities`}
      rightSection={
        <>
          {(pmsUpdated || consentFormUpdated) && (
            <>
              <Button
                size="small"
                onClick={() =>
                  saveSettingsData(pmsUpdated ? 'capture' : 'consent')
                }
                disabled={isLoading}
                data-testid="save-capability-changes-button"
                startIcon={
                  isLoading === 'consent' ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    <SaveIcon color="secondary" />
                  )
                }
              >
                <Typography color="secondary">Save Changes</Typography>
              </Button>
              <Button
                size="small"
                className="mg_left_8"
                onClick={resetSettingsData}
                disabled={isLoading}
                data-testid="cancel-capability-changes-button"
                startIcon={
                  <HighlightOffIcon
                    disabled={isLoading}
                    className="theme_color_orange"
                  />
                }
              >
                <Typography className="theme_color_orange">Cancel</Typography>
              </Button>
            </>
          )}
        </>
      }
    >
      <Grid container spacing={2} alignItems="stretch">
        {showPmsIntegration && (
          <Grid item xs={12} className="d-flex align-center ">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} md={4} lg={3} className="d-flex align-center ">
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={styles.toggleTitle}
                >
                  PMS Integration Enabled
                </Typography>

                <ToggleTickSwitch
                  className="mg_left_8"
                  checked={capturePatientId}
                  onChange={handleSwitchChange}
                  disabled={Boolean(isLoading) || capture_patient_id}
                  name="capturePatientId"
                  inputProps={{
                    'aria-label': 'secondary checkbox',
                    'data-testid': 'toggle-capture-patient',
                  }}
                />
              </Grid>

              {capturePatientId && (
                <Grid
                  item
                  xs={12}
                  md={8}
                  lg={9}
                  className="d-flex align-center "
                >
                  <PmsDropdown
                    isLoading={vendorsLoading}
                    data={vendorsList}
                    vendorName={vendorName}
                    onVendorSelect={onVendorSelect}
                    vendorFirmname={vendorFirmname}
                    onFirmnameChange={onFirmnameChange}
                    handleValidateFirmname={handleValidateFirmname}
                    validateLoading={validateLoading}
                    captureLocationId={Boolean(setting_level === 'location')}
                    pmsLocationId={pmsLocationId}
                    onLocIdChange={onLocIdChange}
                    handleValidateLocationId={handleValidateLocationId}
                    validateLocationLoading={validateLocationLoading}
                    reqFields={reqFields}
                    disabled={capture_patient_id || Boolean(isLoading)}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        {showDuplicateAppts && (
          <Grid item xs={12} sm={6} md={3} className="d-flex align-center ">
            <Typography
              variant="body1"
              color="textSecondary"
              className={`${styles.toggleTitle} d-flex align-center`}
            >
              Subscription Payment Appointment
              {renderTooltip(
                'Creating appointment to record membership payment',
              )}
            </Typography>

            <ToggleTickSwitch
              className="mg_left_8"
              checked={duplicatePmsAppt}
              onChange={handleSwitchChange}
              name="duplicatePmsAppt"
              disabled={Boolean(isLoading)}
              inputProps={{
                'aria-label': 'Toggle PMS duplicate appointment',
                'data-testid': 'toggle-duplicate-pms-appt',
              }}
            />

            {Boolean(isLoading === 'appointment') && (
              <CircularProgress size={20} color="primary" />
            )}
          </Grid>
        )}

        {showSeparateLocation && (
          <Grid item xs={12} sm={6} md={3} className="d-flex align-center ">
            <Typography
              variant="body1"
              color="textSecondary"
              className={`${styles.toggleTitle} d-flex align-center`}
            >
              Location-Based Email
              {renderTooltip(
                'Overrides group default to use location contact information for subscription communication',
              )}
            </Typography>

            <ToggleTickSwitch
              className="mg_left_8"
              checked={separateLocation}
              onChange={handleSwitchChange}
              name="separateLocation"
              disabled={Boolean(isLoading)}
              inputProps={{
                'aria-label': 'Allow Separate Location option toggle',
                'data-testid': 'toggle-allow-separate-location',
              }}
            />

            {Boolean(isLoading === 'separateLocation') && (
              <CircularProgress size={20} color="primary" />
            )}
          </Grid>
        )}

        {autolinking && (
          <Grid item xs={12} sm={6} md={3} className="d-flex align-center ">
            <Typography
              variant="body1"
              color="textSecondary"
              className={`${styles.toggleTitle} d-flex align-center`}
            >
              PMS Auto-linking Enabled
              {renderTooltip(
                'Link patients to PMS upon signing up via the patient portal',
              )}
            </Typography>

            <ToggleTickSwitch
              className="mg_left_8"
              checked={pmsAutolink}
              onChange={handleSwitchChange}
              name="pmsAutolink"
              disabled={Boolean(isLoading)}
              inputProps={{
                'aria-label': 'toggle pms checkbox',
                'data-testid': 'toggle-pms-autolink',
              }}
            />

            {Boolean(isLoading === 'link') && (
              <CircularProgress size={20} color="primary" />
            )}
          </Grid>
        )}

        {patientSearch && (
          <Grid item xs={12} sm={6} md={3} className="d-flex align-center ">
            <Typography
              variant="body1"
              color="textSecondary"
              className={styles.toggleTitle}
            >
              All-patient Appointment Lookup
            </Typography>

            <ToggleTickSwitch
              className="mg_left_8"
              checked={pmsPatientSearch}
              onChange={handleSwitchChange}
              name="pmsPatientSearch"
              disabled={Boolean(isLoading)}
              inputProps={{
                'aria-label': 'PMS Patient Search checkbox',
                'data-testid': 'toggle-pms-patient-search',
              }}
            />

            {Boolean(isLoading === 'search') && (
              <CircularProgress size={20} color="primary" />
            )}
          </Grid>
        )}

        {showEmployeeSupport && (
          <Grid item xs={12} sm={6} md={3} className="d-flex align-center ">
            <Typography
              variant="body1"
              color="textSecondary"
              className={styles.toggleTitle}
            >
              Enable Employee Subscription
            </Typography>

            <ToggleTickSwitch
              className="mg_left_8"
              checked={employeeSupport}
              onChange={handleSwitchChange}
              name="employeeSupport"
              disabled={Boolean(isLoading)}
              inputProps={{
                'aria-label': 'Employee Support checkbox',
                'data-testid': 'toggle-employee-support',
              }}
            />

            {Boolean(isLoading === 'employee') && (
              <CircularProgress size={20} color="primary" />
            )}
          </Grid>
        )}

        {showAutoAssignPlan && (
          <Grid item xs={12} sm={6} md={3} className="d-flex align-center ">
            <Typography
              variant="body1"
              color="textSecondary"
              className={`${styles.toggleTitle} d-flex align-center`}
            >
              Auto Assign Plans to Location
              {renderTooltip(
                'Auto-assigning all plans from the group to all locations under them',
              )}
            </Typography>

            <ToggleTickSwitch
              className="mg_left_8"
              checked={autoAssignPlan}
              onChange={handleSwitchChange}
              name="autoAssignPlan"
              disabled={Boolean(isLoading)}
              inputProps={{
                'aria-label': 'Auto assign plans to location toggle',
                'data-testid': 'toggle-auto-assign-plan',
              }}
            />

            {Boolean(isLoading === 'autoAssignPlan') && (
              <CircularProgress size={20} color="primary" />
            )}
          </Grid>
        )}

        {showAllowGetAddons && (
          <Grid item xs={12} sm={6} md={3} className="d-flex align-center ">
            <Typography
              variant="body1"
              color="textSecondary"
              className={styles.toggleTitle}
            >
              Allow Get-addons option
            </Typography>

            <ToggleTickSwitch
              className="mg_left_8"
              checked={allowGetAddons}
              onChange={handleSwitchChange}
              name="allowGetAddons"
              disabled={Boolean(isLoading)}
              inputProps={{
                'aria-label': 'Allow Get-addons option toggle',
                'data-testid': 'toggle-allow-get-addons',
              }}
            />

            {Boolean(isLoading === 'allowGetAddons') && (
              <CircularProgress size={20} color="primary" />
            )}
          </Grid>
        )}

        {showAllowFacility && (
          <Grid item xs={12} sm={6} md={3} className="d-flex align-center ">
            <Typography
              variant="body1"
              color="textSecondary"
              className={`${styles.toggleTitle} d-flex align-center`}
            >
              Allow Facility
              {renderTooltip(
                'Option to capture facility ID when creating a subscriber',
              )}
            </Typography>

            <ToggleTickSwitch
              className="mg_left_8"
              checked={allowFacility}
              onChange={handleSwitchChange}
              name="allowFacility"
              disabled={Boolean(isLoading)}
              inputProps={{
                'aria-label': 'Allow Facility option toggle',
                'data-testid': 'toggle-allow-facility',
              }}
            />

            {Boolean(isLoading === 'allowFacility') && (
              <CircularProgress size={20} color="primary" />
            )}
          </Grid>
        )}

        {showEnableInsurancPlan && (
          <Grid item xs={12} sm={6} md={3} className="d-flex align-center ">
            <Typography
              variant="body1"
              color="textSecondary"
              className={styles.toggleTitle}
            >
              Enable Insurance Plan
            </Typography>

            <ToggleTickSwitch
              className="mg_left_8"
              checked={pmsDiscountCode}
              onChange={handleSwitchChange}
              name="pmsDiscountCode"
              disabled={Boolean(isLoading)}
              inputProps={{
                'aria-label': 'Allow enable insurance plan option toggle',
                'data-testid': 'toggle-enable-insurance-plan',
              }}
            />

            {Boolean(isLoading === 'pmsDiscountCode') && (
              <CircularProgress size={20} color="primary" />
            )}
          </Grid>
        )}

        {showPatientConsent && (
          <Grid item xs={12} className="d-flex align-center ">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} md={4} lg={3} className="d-flex align-center ">
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={styles.toggleTitle}
                >
                  Patient Consent Form
                </Typography>

                <ToggleTickSwitch
                  className="mg_left_8"
                  checked={enablePatientConsent}
                  onChange={handleSwitchChange}
                  disabled={isLoading}
                  name="enablePatientConsent"
                  inputProps={{
                    'aria-label': 'secondary checkbox',
                    'data-testid': 'toggle-patient-consent',
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={3} className="d-flex align-center ">
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={styles.toggleTitle}
                >
                  Enable Manual Appointment
                </Typography>

                <ToggleTickSwitch
                  className="mg_left_8"
                  checked={enableManualAppointment}
                  onChange={handleSwitchChange}
                  disabled={isLoading}
                  name="enableManualAppointment"
                  inputProps={{
                    'aria-label': 'secondary checkbox',
                    'data-testid': 'toggle-manual-appointment',
                  }}
                />
              </Grid>

              <Grid item xs={12} md={8} lg={9} className="d-flex align-center ">
                <PatientConsent
                  showForm={enablePatientConsent}
                  isLoading={consentVendorsLoading}
                  data={consentVendorsList}
                  consentVendorName={consentVendorName}
                  onConsentVendorSelect={onConsentVendorSelect}
                  consentConfigName={consentConfigName}
                  onConfigNameChange={onConsentConfignameChange}
                  consentFormUrl={consentFormUrl}
                  onFormUrlChange={onConsentFormUrlChange}
                  reqFields={reqFields}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </SectionWrapper>
  );
};

Capabilities.propTypes = {
  name: PropTypes.string,
  levelData: PropTypes.object,
  autolinking: PropTypes.bool,
  patientSearch: PropTypes.bool,
  showEmployeeSupport: PropTypes.bool,
  showAllowFacility: PropTypes.bool,
  showEnableInsurancPlan: PropTypes.bool,
  showSeparateLocation: PropTypes.bool,
  showPatientConsent: PropTypes.bool,
  showPmsIntegration: PropTypes.bool,
  showDuplicateAppts: PropTypes.bool,
  showAutoAssignPlan: PropTypes.bool,
  showAllowGetAddons: PropTypes.bool,
  settings: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default Capabilities;
