import React from "react";
import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";

import reportWebVitals from "./reportWebVitals";
import amplifyConfig from "./aws-export";

// App Provider
import { Provider } from "./context";

import Routes from "./Routes/Routes";
import "./assets/utils.scss";
import "./assets/style.scss";

// New relic:
import "./new-relic";

Amplify.configure(amplifyConfig);

const { REACT_APP_ENVIRONMENT = '', REACT_APP_SENTRY_DSN = '' } =
  process.env || {};

if (REACT_APP_ENVIRONMENT === 'production' && REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment: REACT_APP_ENVIRONMENT,
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function SubscribiliApp() {
  return (
    <Provider>
      <Router>
        <Routes />
      </Router>
    </Provider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <SubscribiliApp />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
