import { BrowserAgent } from '@newrelic/browser-agent';

const {
  REACT_APP_NEW_RELIC_LICENCE_KEY,
  REACT_APP_NEW_RELIC_APPLICATION_ID,
  REACT_APP_NEW_RELIC_ACCOUNT_ID,
  REACT_APP_NEW_RELIC_TRUST_KEY,
} = process.env;

const options = {
  loader_config: {
    licenseKey: REACT_APP_NEW_RELIC_LICENCE_KEY,
    applicationID: REACT_APP_NEW_RELIC_APPLICATION_ID,
    accountID: REACT_APP_NEW_RELIC_ACCOUNT_ID,
    trustKey: REACT_APP_NEW_RELIC_TRUST_KEY,
  },

  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  },

  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: REACT_APP_NEW_RELIC_LICENCE_KEY,
    applicationID: REACT_APP_NEW_RELIC_APPLICATION_ID,
    sa: 1,
  },
};

try {
  if(REACT_APP_NEW_RELIC_LICENCE_KEY) {
    new BrowserAgent(options);
  }
} catch (error) {
  console.error('Error initializing New Relic:', error);
}
